import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "lucide-react";
import {
  addNewBreadcrumbs,
  addToHistoryStack,
  handleBreadcrumbsClick,
  removeBackBreadcrumb,
  removeBreadcrumb,
  removeBreadcrumbByUrl,
  restoreBreadcrumb,
  updateBreadcrumbs
} from "../../../store/slices/breadcrumbsSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { handleTabfunction, selecteTab } from "../../../store/slices/TabSlice";
import BackButton from "../BackButton";
import { printConsole } from "../../helper/Common";

const FinalNewBreadcrumbs = () => {
  const dispatch = useDispatch();
  const breadcrumbsFromStore = useSelector((state) => state.BreadCrumbs.breadcrumbs);
  const removeBreadcrumbstore = useSelector((state) => state.BreadCrumbs.removedBreadcrumbs);
  const StackFromStore = useSelector((state) => state.BreadCrumbs.stack);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState(location.pathname);
  const [navigationSource, setNavigationSource] = useState('regular');

  useEffect(() => {
    if (location.state?.fromForm) {
      setNavigationSource('form');
    } else {
      setNavigationSource('regular');
    }
  }, [location]);

  useEffect(() => {
    setCurrentUrl(location.pathname);
    if (breadcrumbsFromStore && breadcrumbsFromStore.length === 1 &&
      breadcrumbsFromStore[0].url === location.pathname) {
      dispatch(updateBreadcrumbs([]));
    }
  }, [location.pathname, breadcrumbsFromStore, dispatch]);

  const handleDispatch = (url, index) => {
    if (breadcrumbsFromStore && index === breadcrumbsFromStore.length - 1) return;

    if (url !== currentUrl) {
      setCurrentUrl(url);
      const updatedBreadcrumbs = breadcrumbsFromStore.slice(0, index + 1);
      dispatch(updateBreadcrumbs(updatedBreadcrumbs));

      const breadcrumbTab = breadcrumbsFromStore[index].tab;
      const breadcrumbType = breadcrumbsFromStore[index].type;

      if (breadcrumbTab && breadcrumbType) {
        dispatch(handleTabfunction({ value: breadcrumbTab, type: breadcrumbType }));
      }

      const accountId = breadcrumbsFromStore?.[index]?.accountId || "";
      const accountName = breadcrumbsFromStore?.[index]?.accountName || "";

      const locationObj = {};
      if (accountId && accountName) {
        locationObj.accountId = accountId;
        locationObj.accountName = accountName;
      }

      navigate(url, { replace: true, state: locationObj });
    }
  };
  printConsole("StackFromStore", StackFromStore) // print console.log

  const handleBackNavigation = () => {
    const lastBreadcrumb = breadcrumbsFromStore[breadcrumbsFromStore.length - 1];
    const lastStackItem = StackFromStore[StackFromStore.length - 1];

    if (lastStackItem && lastStackItem !== lastBreadcrumb) {
      printConsole("This Code is running", lastStackItem?.url, lastBreadcrumb?.url) // print console.log
      dispatch(addNewBreadcrumbs(lastStackItem));
      dispatch(removeBreadcrumbByUrl(lastStackItem.url));
      navigate(-1);
    } else {
      dispatch(removeBreadcrumb(lastBreadcrumb.url));
      navigate(-1);
    }
  };

  printConsole({ breadcrumbsFromStore }) // print console.log

  return (
    <>
      <div onClick={breadcrumbsFromStore.length === 0 ? () => navigate(-1) : handleBackNavigation}>
        <BackButton />
      </div>



      <nav className="flex items-center p-4" style={{ marginLeft: "-32px", marginBottom: "-36px" }}>
        {breadcrumbsFromStore && breadcrumbsFromStore.map((breadcrumb, index) => (
          index >= breadcrumbsFromStore.length - 3 && (
            <React.Fragment key={breadcrumb.url || index}>
              {breadcrumbsFromStore.length > 1 &&
                ((breadcrumbsFromStore.length - 1 === 0 ||
                  (breadcrumbsFromStore.length > 2 && index === breadcrumbsFromStore.length - 2) ||
                  index === breadcrumbsFromStore.length - 1) && (
                    <ChevronRight className="w-4 h-4 mx-2 text-gray-400" />
                  ))}

              <span
                onClick={() => handleDispatch(breadcrumb.url, index)}
                style={index === breadcrumbsFromStore.length - 1 ? {} : { color: "#0c71c3" }}
                className={`flex items-center text-sm group transition-all duration-300 ease-in-out ${index === breadcrumbsFromStore.length - 1
                  ? "text-gray-600 cursor-not-allowed"
                  : breadcrumb.url === currentUrl
                    ? "text-gray-600 cursor-not-allowed"
                    : "hover:text-[#0a5ca0] cursor-pointer"
                  }`}
              >
                {breadcrumb.icon && (
                  <span className="mr-1.5 group-hover:text-[#0a5ca0] transition-colors duration-300">
                    {breadcrumb.icon}
                  </span>
                )}

                <span className="relative">
                  {breadcrumb.title}
                  {breadcrumb.url !== currentUrl && (
                    <span className="absolute bottom-0 left-0 w-0 h-0.5 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                  )}
                </span>
              </span>
            </React.Fragment>
          )
        ))}
      </nav>
    </>
  );
};

export default FinalNewBreadcrumbs;