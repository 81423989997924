import React, { memo, useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

const ContactsCard = memo(({ sections }) => {
  const [contacts, setContacts] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = "contacts";
  const account = findSectionById("accounts", sections);

  const handleDetailsDispatch = (accountId, item) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
    };
    
    const handleDispatch = () => {
      BreadCrumbsObject.title = "Dashboard";
      dispatch(
        addNewBreadcrumbs({ ...BreadCrumbsObject, url: "/dashboard" })
      );
    };
    handleDispatch();
    if (!account?.account_id) {
      if (Number(isPermission({ type: "dashboard", name: "ContactListing" })) === 1) {
        BreadCrumbsObject.title = "Contact Listing";
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: "/Admin/Contact-listing",
          })
        );
      }
    } else if (Number(contacts?.totalcount) > 1) {
      if (Number(isPermission({ type: "dashboard", name: "ContactListing" })) === 1) {
        BreadCrumbsObject.title = item;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account-details/${accountId}/${item}`,

          })
        );
      }
    } else {
      if (Number(isPermission({ type: "dashboard", name: "ContactDetails" })) === 1) {
        BreadCrumbsObject.title = `Contact name ${item}`;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account/${accountId}/contact-details/${contacts?.contact_id}/${item}`,
          })
        );
      }
    }
  };

  const navigateContact = () => {
    if (!account?.account_id) {
      if (Number(isPermission({ type: "dashboard", name: "ContactListing" })) === 1) {
        handleDetailsDispatch();
        navigate("/Admin/Contact-listing");
      }
    } else if(Number(contacts?.totalcount) > 1) {
      if (Number(isPermission({ type: "dashboard", name: "ContactListing" })) === 1) {
        handleDetailsDispatch(account?.account_id, "Contacts");
        navigate(`/account-details/${account?.account_id}/Contacts`);
      }
    } else {
      if(Number(isPermission({ type: "dashboard", name: "ContactDetails" })) === 1) {
        handleDetailsDispatch(account?.account_id, "Details");
        navigate(`/account/${account?.account_id}/contact-details/${contacts?.contact_id}/Details`);

      }
    }
  };

  // const navigateContact = () => {
  //   if (!account?.account_id) {
  //     Number(isPermission({ type: "dashboard", name: "ContactListing" })) ===
  //       1 && navigate("/Admin/Contact-listing");
  //   } else if (Number(contacts?.totalcount) > 1) {
  //     Number(isPermission({ type: "dashboard", name: "ContactListing" })) ===
  //       1 && navigate(`/account-details/${account?.account_id}/Contacts`);
  //   } else {
  //     navigate(
  //       Number(isPermission({ type: "dashboard", name: "ContactDetails" })) ===
  //         1 &&
  //         `/account/${account?.account_id}/contact-details/${contacts?.contact_id}/Details`
  //     );
  //   }
  // };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setContacts(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        id="contacts-card"
        className="text-center text-lg font-bold mb-2"
        style={{ cursor: Number(contacts?.totalcount) > 0 ? "pointer" : "" }}
        onClick={() => Number(contacts?.totalcount) > 0 && navigateContact()}
      >
        {contacts?.totalcount}
      </h3>
      <p className="text-center">Contacts</p>
    </div>
  );
});

export default ContactsCard;
