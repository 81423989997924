import React, { useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
// import { Button as FixedButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services/index";
// import Drawer from "@mui/material/Drawer";
import SubHeading from "../../../domains/dashboard/components/header/SubHeading";
// import Filter from "../../../components/filter/";
// import { createTheme } from "@mui/material";
// import { styled, useTheme } from "@mui/material/styles";
import TableSkeleton from "../../../domains/accounts/components/skeleton/table/TableSkeleton";
// import SupportListTbl from "./SupportListTbl";
import {
  GetProfile,
  prepareOptions,
  printConsole,
} from "../../../common/helper/Common";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import {
  addNewBreadcrumbs,
  clearBreadcrumbs,
  handleBreadcrumbsClick,
} from "../../../store/slices/breadcrumbsSlice";
import { DecryptToken } from "../../../common/helper";
import { isPermission } from "../../../common/helper/PermissionManager";
import { handleTabfunction, selecteTab } from "../../../store/slices/TabSlice";
import { AccountContactsTab } from "../../../common/hooks";
import { truncateText } from "../../../common/helper/BasicFn";

const SupportListing = ({
  support_api,
  fetch_data_key,
  isSearchable,
  method,
}) => {
  const navigate = useNavigate();
  const LoginuserData = DecryptToken();
  const currentTab = "Details";
  const [showLoading, setShowLoading] = React.useState(false);
  // const [accounts, setAccounts] = useState([]);
  const [filteredAccount, setFilterdAccount] = useState([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [siteDataList, setsiteDataList] = useState([]);
  const dispatch = useDispatch();
  // const [supportList, setSupportList] = useState();
  const [issueTypeList, setIssueTypeList] = useState();
  // const [loading, setLoading] = useState(true);

  // let userData = GetProfile();//JSON.parse(localStorage.getItem("ross-profile"));
  // let account_id = userData?.account_id;
  // let contact_id = userData?.contact_id;
  useEffect(() => {
    if (isSearchable) {
      dispatch(clearBreadcrumbs());
    }
  }, [isSearchable, dispatch]);

  // const getSupportList = async (e) => {
  //     setShowLoading(true);
  //     const issueTypeResult = await CallGETAPI("aed/all-issues-type");
  //     // const issueTypeResult = await CallGETAPI("admin/admin-support-list-v1");

  //     if (issueTypeResult?.status) {
  //         const issueTypes = issueTypeResult?.data?.data?.issuesList;

  //         const allIssueTypes = prepareOptions(
  //             issueTypes,
  //             "issue_id",
  //             "issue_name"
  //         );

  //         setIssueTypeList(allIssueTypes);
  //     }

  // get support details
  // if (isSearchable == true) {
  //     const result = await CallPOSTAPI("support/support-list");

  //     if (result?.status) {
  //         const resultData = result?.data?.data?.ticketData;

  //         setSupportList(resultData);
  //     }
  // }

  //     setShowLoading(false);
  // };

  // useEffect(() => {
  //     getSupportList();
  // }, []);

  // const handleDrawerOpen = () => {
  //     setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //     setOpen(false);
  // };

  const location = useLocation();
  // const showDelete = location?.state?.showDelete;

  // const [searchInput, setSearchINput] = useState({
  //     name: "",
  //     equipment: "",
  //     training: "",
  //     type: "",
  //     parent: "",
  //     distributor: "",
  //     owner: "",
  //     secure: "",
  // });

  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModel(false);
  };

  // useEffect(() => {
  //     let filteredData = accounts;
  //     if (searchInput.name !== "") {
  //         filteredData = filteredData.filter(({ account_name }) =>
  //             account_name
  //                 .toLocaleLowerCase()
  //                 .includes(searchInput.name.toLocaleLowerCase())
  //         );
  //     }
  //     if (searchInput.type !== "") {
  //         filteredData = filteredData.filter(({ customer_type_name }) =>
  //             customer_type_name
  //                 .toLocaleLowerCase()
  //                 .includes(searchInput.type.toLocaleLowerCase())
  //         );
  //     }
  //     if (searchInput.parent !== "") {
  //         filteredData = filteredData.filter(({ parent_name }) =>
  //             parent_name
  //                 .toLocaleLowerCase()
  //                 .includes(searchInput.parent.toLocaleLowerCase())
  //         );
  //     }
  //     if (searchInput.distributor !== "") {
  //         filteredData = filteredData.filter(({ distributon_name }) =>
  //             distributon_name
  //                 .toLocaleLowerCase()
  //                 .includes(searchInput.distributor.toLocaleLowerCase())
  //         );
  //     }
  //     if (searchInput.secure !== "") {
  //         filteredData = filteredData.filter(
  //             ({ isSecure }) => isSecure == Number(searchInput.secure)
  //         );
  //     }

  //     setFilterdAccount(filteredData);
  // }, [searchInput]);

  // const [isAsc, setIsAsc] = useState(false);

  // const handleSorting = (key) => {
  //     let sortedData = [...filteredAccount];
  //     if (sortedData?.[0]?.[key] === undefined) {
  //         return;
  //     }
  //     // isSecure

  //     const data = sortedData.sort((a, b) => {
  //         if (Number.isInteger(a[key])) {
  //             if (isAsc) {
  //                 return b[key] - a[key];
  //             } else {
  //                 return a[key] - b[key];
  //             }
  //         } else {
  //             let fa = a[key].toLowerCase(),
  //                 fb = b[key].toLowerCase();
  //             if (isAsc) {
  //                 if (fa < fb) {
  //                     return 1;
  //                 }
  //                 if (fa > fb) {
  //                     return -1;
  //                 }
  //                 return 1;
  //             } else {
  //                 if (fa < fb) {
  //                     return -1;
  //                 }
  //                 if (fa > fb) {
  //                     return 1;
  //                 }
  //                 return 0;
  //             }
  //         }
  //     });

  //     setFilterdAccount(data);
  //     setIsAsc(!isAsc);
  // };
  const [isAsc, setIsAsc] = useState(false);

  const handleSorting = (newData) => {
    let sortedData = [...newData];
    // Check if the status key exists in the first object
    if (sortedData?.[0]?.status === undefined) {
      return [];
    }
    sortedData.sort((a, b) => a.status - b.status);
    return sortedData || [];
  };

  // const getIssue = (data) => {
  //     return (
  //         <>
  //             <span
  //                 className="link"
  //                 style={{ fontWeight: "bold", textDecoration: "none" }}
  //                 onClick={() => { navigate("/account/support/" + data?.ticket_id) }}
  //             >
  //                 {data?.issue}
  //             </span>
  //         </>
  //     );
  // };

  // getStatus for table
  let type = "";
  const BreadCrumbsObject = {
    title: "",
    type: "",
    tab: "",
  };
  const handleDispatch = (type) => {
    BreadCrumbsObject.title = "Support List";
    // BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = type;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/support-listing",
      })
    );
  };

  const handleDetailDispatch = (supportId, issue_name) => {
    // BreadCrumbsObject.type = "Details";
    BreadCrumbsObject.title = issue_name + " Details";
    // BreadCrumbsObject.type = type;
    const url = "/account/supportDetails/" + supportId;
    dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: url }));
  };
  const handleRelationDispatch = (url1, relation, accountId, type) => {
    const url = url1;
    BreadCrumbsObject.title = relation + " Details";
    BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = type;
    // BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: url,
      })
    );
  };
  const statusUpdate = async (supportId) => {
    const statusTypeResult = await CallGETAPI(
      "support/changed-status/" + supportId
    );

    if (statusTypeResult?.status) {
      printConsole("Successfullu updated"); // print console.log
    } else {
      printConsole("statusTypeResult", statusTypeResult); // print console.log
    }
  };
  const getIssueType = (data) => {
    const hasPermission =
      Number(isPermission({ type: "link", name: "supportDetails" })) === 1;

    return (
      <>
        {hasPermission ? (
          <span
            className="link"
            style={{ fontWeight: "bold", textDecoration: "none" }}
            onClick={() => {
              if (data?.status === 0 && LoginuserData === data?.assign_to) {
                statusUpdate(data?.ticket_id);
              }
              if (isSearchable) {
                handleDispatch(type);
              }
              handleDetailDispatch(
                data?.ticket_id,
                data.issue_name,
                data?.account_id
              );
              navigate("/account/supportDetails/" + data?.ticket_id);
            }}
            title={
              data.issue_name.length > 20 ? data.issue_name : undefined // Tooltip only if the text is truncated
            }
          >
            {truncateText(data.issue_name, 20)}
          </span>
        ) : (
          <span style={{ fontWeight: "bold", textDecoration: "none" }}>
            {data.issue_name}
          </span>
        )}
      </>
    );
  };

  const getRelation = (data) => {
    let url = "";

    switch (data?.related_to) {
      case "site":
        url = Number(isPermission({ type: "link", name: "siteName" }) === 1)
          ? "/account/site-details/" + data?.site_id + "/Details"
          : "";
        // type = "site";
        break;
      case "Site":
        url = Number(isPermission({ type: "link", name: "siteName" }) === 1)
          ? "/account/site-details/" + data?.site_id + "/Details"
          : "";
        // type = "site";
        break;
      case "account" || "Account":
        url = Number(isPermission({ type: "link", name: "accountName" }) === 1)
          ? "/account-details/" + data?.account_id + "/Details"
          : "";

        // type = "account";
        break;
      case "Account":
        url = Number(isPermission({ type: "link", name: "accountName" }) === 1)
          ? "/account-details/" + data?.account_id + "/Details"
          : "";

        // type = "account";
        break;
      case "Contact":
        url = Number(isPermission({ type: "link", name: "contactName" }) === 1)
          ? "/account/" +
            data?.account_id +
            "/contact-details/" +
            data?.contact_id +
            "/Details"
          : "";
        // type = "contact";
        break;
      case "AED":
        url = Number(isPermission({ type: "link", name: "aedBrand" }) === 1)
          ? "/account/aed-details/" + data?.aed_id + "/Details"
          : "";
        // type = "aed";
        break;
      case "aed":
        url = Number(isPermission({ type: "link", name: "aedBrand" }) === 1)
          ? "/account/aed-details/" + data?.aed_id + "/Details"
          : "";
        // type = "aed";
        break;
      case "AED check":
        url = Number(isPermission({ type: "link", name: "aedCheckDate" }) === 1)
          ? "/account/aed-checks-details/" +
            data?.aed_id +
            "/" +
            data?.aed_check_id +
            "/Details"
          : "";
        // type = "aed";
        break;
      case "AED service modification":
        url = Number(
          isPermission({ type: "link", name: "aedServiceDate" }) === 1
        )
          ? `/account-details/AEDServiceDetails/${data?.aed_id}/${data?.aed_services_id}`
          : "";
        // BreadCrumbsObject.type = "Aed Servicing";
        break;
      case "AED servicing":
        url = Number(
          isPermission({ type: "link", name: "aedServiceDate" }) === 1
        )
          ? `/account-details/AEDServiceDetails/${data?.aed_id}/${data?.aed_services_id}`
          : "";
        // BreadCrumbsObject.type = "Aed Servicing";
        break;
      // case "AED Questions":
      //     url = `/account-details/AEDServiceDetails/${data?.aed_id}/${data?.aed_question_id
      //         }`;
      //     BreadCrumbsObject.type = "Aed Servicing";
      //     break;
    }
    return (
      <span
        onClick={() => {
          if (data?.related_to !== "AED Questions") {
            if (isSearchable) {
              handleDispatch(type);
            }
            if (url != "") {
              handleRelationDispatch(url, data?.relation, data?.account_id);
              navigate(url);
            }
          }
        }}
        className={url !== "" && "link"}
        style={{
          textDecoration: "none",
          color: url !== "" ? "#0c71c3" : "black",
          cursor: url !== "" ? "pointer" : "default",
        }}
        title={
          data?.relation?.length > 20 ? data?.relation : undefined // Tooltip only if the text is truncated
        }
      >
        {data?.related_to} : {truncateText(data?.relation, 20)}
      </span>
    );
  };

  // getStatus for table
  const getStatus = (data) => {
    switch (data?.status) {
      case 0:
        return <span className="text-secondary">Not Viewed</span>;

      case 1:
        return <span className="text-danger">Active</span>;
      case 2:
        return <span className="text-warning">Waiting</span>;
      case 3:
        return <span style={{ color: "green" }}>Completed</span>;
    }
  };

  const headerColumns = [
    {
      Header: "Issue Type",
      accessor: "issue_name",
      width: "12%",
      Cell: ({ row }) => {
        return getIssueType(row.original);
      },
      disableSortBy: true,
    },

    // {
    //     Header: "Issue",
    //     accessor: "issue",
    //     width: "12%",
    //     Cell: ({ row }) => {
    //         return getIssue(row.original)
    //     },
    //     disableSortBy: true,
    // },
    {
      Header: "Relation",
      accessor: "relation",
      width: "12%",
      Cell: ({ row }) => {
        return getRelation(row.original);
      },
      disableSortBy: true,
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      Cell: ({ row }) => (
        <span>
          <Moment date={row?.original.due_date} format={"MM/DD/YYYY h:mm A"} />
        </span>
      ),
      width: "12%",
    },
    {
      Header: "Created By ",
      accessor: "created_by",
      width: "12%",
    },
    {
      Header: "Created Date",
      accessor: "created_date",
      Cell: ({ row }) => (
        <span>
          <Moment
            date={row?.original.created_date}
            format={"MM/DD/YYYY h:mm A"}
          />
        </span>
      ),
      width: "15%",
    },
    {
      Header: "Owner Name",
      accessor: "owner_name",
      disableSortBy: false,
      width: "15%",
      disableSortBy: true,
    },

    {
      Header: "Status",
      accessor: "status",
      width: "12%",
      Cell: ({ row }) => {
        return getStatus(row?.original);
      },
      disableSortBy: true,
    },
  ];
  return (
    <>
      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>

      <div className="mt-4" style={{ paddingInline: "0px" }}>
        {isSearchable === true && (
          <SubHeading
            hideNew={true}
            hideHierarchy={true}
            title={"Support Listing"}
            subHeading={true}
          />
        )}

        <div style={{ minHeight: "84.6vh" }}>
          <NewGlobalTable
            method={method || "GET"}
            isSearchable={isSearchable || false}
            isCache={true}
            pageSize={50}
            customRenderData={handleSorting}
            apiEndPoint={support_api}
            keyAPiName={"ticketData"}
            headerColumns={headerColumns}
          />
        </div>
      </div>
    </>
  );
};

export default SupportListing;
