import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import { DecryptToken, GetContactList } from "../../../common/helper/BasicFn";
import { sortArrAscending } from "../../../common/helper/constants";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import UpdateRoleToUserModal from "../component/UpdateRoleToUserModal";
import EditAssignAdminModal from "../Model/EditAssignAdminModal";
import { printConsole } from "../../../common/helper/Common";

const AssignAdmin = () => {
  const user = DecryptToken();
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [userTableData, setUserTableData] = useState([]);
  const [tableReset, setTableReset] = useState(false);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [edit, setEdit] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [formKey, setFormKey] = useState(0);
  const [formData, setFormData] = useState({
    contact_id: "",
    admin_role_id: [],
    user_role_id: [],
    graph_id: [],
  });
  const [updateModal, setUpdateModal] = useState(false);
  const [contactID, setContactID] = useState("");
  const [sortedContactList, setSortedContactList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isInvalid, setIsInvalid] = useState({
    contact_id: false,
    admin_role_id: false,
    user_role_id: false,
    graph_id: false,
  });

  const fetchLoad = async () => {
    const contactDataRes = await GetContactList();
    const contactData = contactDataRes?.data?.data?.contact_list || [];

    let sortedArr = sortArrAscending(contactData, "contact_name");
    setSortedContactList(sortedArr);

    const res = await CallGETAPI(
      `permission/get-role?search=&page=${1}&pageSize=${50}`
    );
    const roleDataRes = res?.data?.data?.role || [];
    setRoleData(roleDataRes);
  };

  useEffect(() => {
    fetchLoad();
    fetchUserTblData();
    fetchUserDropdown();
  }, []);

  const fetchUserTblData = async () => {
    setTableReset(true);
    setTimeout(() => {
      setTableReset(false);
    }, 1000);
  };

  const fetchUserDropdown = () => {
    setResetDropdown(true);
    setTimeout(() => {
      setResetDropdown(false);
    }, 1000);
  }

  const handleChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const HandleAssign = async (data) => {
    setContactID(data.account_main_contact_id);
    setUpdateModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let is_false = 0;
    let obj = {
      contact_id: false,
      admin_role_id: false,
      user_role_id: false,
      graph_id: false,
    };

    if (formData.contact_id === "") {
      obj.contact_id = true;
      is_false = 1;
    }

    if (
      formData.admin_role_id?.length === 0 &&
      formData.user_role_id?.length === 0
    ) {
      obj.admin_role_id = true;
      obj.user_role_id = true;
      is_false = 1;
    }
    // if (formData.graph_id === "") {
    //   obj.graph_id = true;
    //   is_false = 1;
    // }

    if (is_false) {
      setIsInvalid(obj);
      // toast.error("Please fill in all required fields.");
      return "";
    }

    setLoading(true);
    let adminRolesArr = formData.admin_role_id;
    let finalAdminRolesArr = adminRolesArr.map((role) => role.value);

    let userRolesArr = formData.user_role_id;
    let finalUserRolesArr = userRolesArr.map((role) => role.value);

    let body = {
      contact_id: formData.contact_id,
      admin_role_id: finalAdminRolesArr + "",
      user_role_id: finalUserRolesArr + "",
      graph_id: formData.graph_id,
    };

    const res = await CallPOSTAPI("permission/assign-admin-user-role", body);
    // const res = await CallPOSTAPI("admin/assign-admin-user-role", body);
    printConsole(res); // print console.log
    if (res?.data?.status) {
      setLoading(false);
      toast.success(res?.data?.msg);
      fetchUserTblData();
      fetchUserDropdown();
      setFormData({
        contact_id: "",
        admin_role_id: [],
        user_role_id: [],
        graph_id: [],
      });
      setFormKey((prevKey) => prevKey + 1);
    } else {
      setLoading(false);
      toast.error(res?.data?.msg);
      setFormData({
        contact_id: "",
        admin_role_id: [],
        user_role_id: [],
        graph_id: [],
      });
    }
  };

  const [sortedNewRoleDataArr, setSortedNewRoleDataArr] = useState([]);
  const [sortedAdminRoleDataArr, setSortedAdminRoleDataArr] = useState([]);
  const [sortedUserTableData, setSortedUserTableData] = useState([]);
  const [sortedGraphDataArr, setSortedGraphDataArr] = useState([
    {
      id: 1,
      value: "aed_tech_service_check",
      name: "AED Tech Servicing Check",
    },
    {
      id: 2,
      value: "aed_tech_assessment",
      name: "AED Tech Assignments",
    },
  ]);

  const graph = sortedGraphDataArr.map((obj) => {
    return { label: obj.name, value: obj.value };
  });

  const handleLostVars = () => {
    try {
      const adminRoles = roleData
        .map((obj) => {
          if (obj.is_admin_role === 1) {
            return { label: obj.role_name, value: obj.role_id };
          }
          return null;
        })
        .filter((item) => item !== null);

      let sortedAdminArr = sortArrAscending(adminRoles, "label");
      setSortedAdminRoleDataArr(sortedAdminArr);

      const userRoles = roleData
        .map((obj) => {
          if (obj.is_admin_role === 0) {
            return { label: obj.role_name, value: obj.role_id };
          }
          return null;
        })
        .filter((item) => item !== null);

      let sortedUserArr = sortArrAscending(userRoles, "label");
      setSortedNewRoleDataArr(sortedUserArr);
    } catch (e) {}
  };

  useEffect(() => {
    handleLostVars();
  }, [roleData]);

  useEffect(() => {
    let sortedArr = sortArrAscending(userTableData, "contactName");
    setSortedUserTableData(sortedArr);
  }, [userTableData]);

  const handleRoleChange = (roleType, e) => {
    setFormData({
      ...formData,
      [roleType]: e,
    });
    setIsInvalid({
      ...isInvalid,
      [roleType]: false,
    });
  };

  const handleGraphChange = (e) => {
    if (formData.admin_role_id.length > 0) {
      setFormData({
        ...formData,
        graph_id: e,
      });
    }
  };

  useEffect(() => {
    if (formData.admin_role_id.length === 0) {
      setFormData((prev) => ({ ...prev, graph_id: [] }));
    }
  }, [formData.admin_role_id.length]);

  const renderSelectedGraphNames = () => {
    return [formData.graph_id.map((item) => item.label).join(", ")];
  };

  const renderSelectGraphTitle = () => {
    return (
      <div>
        {formData.graph_id.length === 0
          ? "-- Select --"
          : formData.graph_id.length >= 2
          ? `${formData.graph_id.length} Selected`
          : renderSelectedGraphNames()}
      </div>
    );
  };

  const renderSelectedTitleNames = (roleType) => {
    return formData[roleType].map((item) => item.label).join(", ");
  };

  const renderSelectTitle = (roleType) => {
    return (
      <div>
        {formData[roleType]?.length === 0
          ? "-- Select --"
          : formData[roleType]?.length >= 3
          ? `${formData[roleType]?.length} Selected`
          : renderSelectedTitleNames(roleType)}
      </div>
    );
  };

  const headerColumns = [
    {
      Header: "Contact Name",
      accessor: "contactName", // Maps to the "contactName" field in your data
    },
    {
      Header: "Admin Role",
      accessor: "adminRole",
      Cell: ({ row }) => {
        const adminRoles = row?.original?.adminRole;

        return (
          <div>
            {adminRoles && adminRoles.length > 0
              ? adminRoles
                  .split(",")
                  .map((role, index) => <div key={index}>{role.trim()}</div>)
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "User Role",
      accessor: "userRole",
      Cell: ({ row }) => {
        const userRoles = row?.original?.userRole;

        return (
          <div>
            {userRoles && userRoles.length > 0
              ? userRoles
                  .split(",")
                  .map((role, index) => <div key={index}>{role.trim()}</div>)
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Graph",
      accessor: "assign_grabh",
      width: "15%",
      Cell: ({ row }) => {
        const data = row?.original?.assign_grabh || [];
        const graphArr = graph.filter((item) => data.includes(item.value));
        return (
          <div>
            {graphArr && graphArr.length > 0
              ? graphArr.map((graph, index) => (
                  <div key={index}>{graph?.label.trim()}</div>
                ))
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Assigned Date",
      accessor: "modify_date",
      width: "12%",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div>
          {moment(row?.original?.modify_date).isValid()
            ? moment(row?.original?.modify_date).format("MM/DD/YYYY h:mm:ss")
            : ""}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions", // No actual field, but this will be rendered in the cell
      width: "10%",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <button
            type="button"
            style={{
              width: "80px",
              height: "30px",
              color: "white",
              background: "#d32f2f",
              border: "none",
              borderRadius: "10px",
            }}
            onClick={() => HandleAssign(row.original)}
          >
            Unassign
          </button>

          <button
            className="text-primary"
            type="button"
            onClick={() => {
              setEdit(true);
              setRowData(row.original);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: "none",
              background: "transparent",
            }}
          >
            <img
              src="/assets/images/edit.svg"
              alt="svg"
              style={{ marginRight: "0px" }}
            />
            <span className="ms-2">Edit</span>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className="mt-4"
        style={{ position: "relative", width: "100%", paddingInline: "0px" }}
      >
        <Box className="text-left pt-3 pb-1">
          <h4 className="heading">Assign Admin</h4>
        </Box>
        <Form
          key={formKey}
          className=""
          onSubmit={handleSubmit}
          noValidate
          id="create-new-equipment-form"
        >
          <div
            className="my-4"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5%",
              marginBottom: "50px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "95%",
                gap: "3%",
              }}
            >
              <Form.Group className={"col"} style={{ maxWidth: "250px" }}>
                <Form.Label>Select Contact*</Form.Label>
                {/* <select
                  className="form-control"
                  value={formData.contact_id}
                  name="contact_id"
                  placeholder="Enter Contact Name"
                  onChange={(e) => {
                    handleChange(e, "contact_id");
                    setIsInvalid({ ...isInvalid, contact_id: false });
                  }}
                  required
                >
                  <option value="" disabled>
                    --Select One--
                  </option>
                  {sortedContactList?.filter((item) => item.status === 1)
                    ?.map((item, index) => (
                      <option value={item?.contact_id} key={index}>
                        {item?.contact_name}
                      </option>
                    ))}
                </select> */}

                <PaginateAutoComplete
                  dropDownName="contact_id"
                  apiEndpoint={"/permission/account-admin-contacts-list"}
                  idKey={"contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"finalData"}
                  // apiEndpoint={"/permission/account-list-user"}
                  // idKey={"account_id"}
                  // valueKey={"account_name"}
                  // parentKey={"allAccount"}
                  // onSelect={(e) => {
                  //   // handleInputChange(e, "account_info");
                  // }}
                  onSelect={(e) => {
                    handleChange(e, "contact_id");
                    setIsInvalid({ ...isInvalid, contact_id: false });
                    // setSelectAccountValidations(false);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  // className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  isUpdated={resetDropdown}
                />

                {isInvalid?.contact_id && (
                  <Form.Control.Feedback type="" className="text-danger mt-1">
                    Please Select Contact.
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className={"col"} style={{ maxWidth: "250px" }}>
                <Form.Label>Admin Role</Form.Label>

                <MultiSelect
                  valueRenderer={() => renderSelectTitle("admin_role_id")}
                  options={sortedAdminRoleDataArr}
                  value={formData.admin_role_id}
                  onChange={(e) => handleRoleChange("admin_role_id", e)}
                  labelledBy="Select"
                  hasSelectAll={false}
                />

                {isInvalid?.admin_role_id && (
                  <Form.Control.Feedback type="" className="text-danger mt-1">
                    Please Select Admin Role.
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className={"col"} style={{ maxWidth: "250px" }}>
                <Form.Label>User Role</Form.Label>

                <MultiSelect
                  valueRenderer={() => renderSelectTitle("user_role_id")}
                  options={sortedNewRoleDataArr}
                  value={formData.user_role_id}
                  onChange={(e) => handleRoleChange("user_role_id", e)}
                  labelledBy="Select"
                  hasSelectAll={false}
                />

                {isInvalid?.user_role_id && (
                  <Form.Control.Feedback type="" className="text-danger mt-1">
                    Please Select User Role.
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              {formData.admin_role_id.length > 0 && (
                <Form.Group className={"col"} style={{ maxWidth: "250px" }}>
                  <Form.Label>Graph</Form.Label>
                  <MultiSelect
                    valueRenderer={renderSelectGraphTitle}
                    options={graph}
                    value={formData.graph_id}
                    onChange={(e) => handleGraphChange(e)}
                    labelledBy="Select"
                    hasSelectAll={false}
                  />
                </Form.Group>
              )}

              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                style={{ marginTop: 25, height: 40 }}
                // disabled={
                //   loading === false
                //     ? isInvalid.contact_id === false
                //       ? isInvalid.admin_role_id === false
                //         ? false
                //         : isInvalid.user_role_id === false
                //         ? false
                //         : true
                //       : true
                //     : true
                // }
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </Form>

        <Box className="py-4">
          <NewGlobalTable
            isSearchable={true}
            isCache={true}
            pageSize={50}
            method="GET"
            apiEndPoint="permission/fetch-admin-user-role"
            keyAPiName="mappedContactsUpdated"
            headerColumns={headerColumns}
            isTblUpdated={tableReset}
          />
        </Box>
        {/* <DataGrid
          className="my-2"
          id=""
          dataSource={sortedUserTableData}
          keyExpr=""
          showBorders={true}
          showRowLines={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
        >
          <Paging defaultPageSize={10} defaultPageIndex={0} />

          <Column
            dataField="contactName"
            caption={"Contact Name"}
            dataType="string"
          />

          <Column
            dataField="adminRole"
            caption={"Admin Role"}
            dataType="string"
            allowSorting={true}
            cellRender={(data) =>
              data?.data.adminRole?.length !== 0 ? data?.data.adminRole : "N/A"
            }
          />

          <Column
            dataField="userRole"
            caption={"User Role"}
            dataType="string"
            allowSorting={true}
            cellRender={(data) =>
              data?.data.userRole?.length !== 0 ? data?.data.userRole : "N/A"
            }
          />

          <Column
            dataField="modify_date"
            caption={"Assign Date"}
            dataType="string"
            allowSorting={true}
            cellRender={(data) =>
              moment(data?.data.modify_date).isValid()
                ? moment(data?.data.modify_date).format("MM/DD/YYYY h:mm:ss")
                : ""
            }
          />

          <Column
            width={170}
            dataField=""
            caption={"Actions"}
            dataType="string"
            allowSorting={true}
            cellRender={(data) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "5px",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      width: "80px",
                      height: "30px",
                      color: "white",
                      background: "#d32f2f",
                      border: "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => HandleAssign(data?.data)}
                  >
                    Unassign
                  </button>

                  <button
                    className="text-primary"
                    type="button"
                    onClick={() => {
                      setEdit(true);
                      setRowData(data.data);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <img
                      src="/edit.svg"
                      alt="svg"
                      style={{ marginRight: "0px" }}
                    />
                    <span className="ms-2">Edit</span>
                  </button>
                </div>
              </>
            )}
          />
        </DataGrid> */}

        <UpdateRoleToUserModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          contactID={contactID}
          fetchCreateTblData={fetchUserTblData}
          fetchUserDropdown={fetchUserDropdown}
        />

        {edit && (
          <EditAssignAdminModal
            show={edit}
            setShow={setEdit}
            newAdminRoleDataArr={sortedAdminRoleDataArr}
            newUserRoleDataArr={sortedNewRoleDataArr}
            newGraphArr={graph}
            contactList={sortedContactList}
            roleData={roleData}
            rowData={rowData}
            fetchUserTblData={fetchUserTblData}
          />
        )}
      </div>
    </>
  );
};

export default AssignAdmin;
