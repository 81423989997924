import React, { useEffect, useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionManagerMenu from "../../../domains/permissionManager/pages/PermissionManagerMenu";
import { useDispatch } from "react-redux";
import { clearBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";

const Sidebar = ({ show, setShow, handleClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigation = (path)=>{
    dispatch(clearBreadcrumbs());
    navigate(path);
  }


  const navLinks = [
    { to: "/dashboard", label: "Dashboard" },
    { to: "/accounts-listing", label: "Accounts" },
    { to: "/Admin/Sites-listing", label: "Site Listing" },
    { to: "/Admin/Contact-listing", label: "Contact Listing" },
    { to: "/Admin/accessory-listing", label: "Accessory Listing" },
    { to: "/Admin/Aed-listing", label: "AED Listing" },
    { to: "/account/support-listing", label: "Support Listing" },
    { to: "/Admin/Note-listing", label: "Notes Listing" },
  ];

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ width: "300px", background: "#000" }}
        id="sidebar-menus"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <Nav className="flex-column">
              {navLinks.map((link, index) => (
                  <div 
                    key={index} 
                    className="nav-link" 
                    style={{ cursor: "pointer" }} 
                    onClick={() => handleNavigation(link.to)}
                  >
                    {link.label}
                  </div>))}
                {/* <Link
                  to="/dashboard"
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                >
                  Dashboard
                </Link>
                <Link
                  to="/accounts-listing"
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                >
                  Accounts
                </Link>
                <Link to="/Admin/Sites-listing" className="nav-link">
                  Site Listing 
                </Link>
                <Link to="/Admin/Contact-listing" className="nav-link">
                  Contact Listing
                </Link>
                <Link to="/Admin/accessory-listing" className="nav-link">
                  Accessory Listing
                </Link>
                <Link to="/Admin/Aed-listing" className="nav-link">
                  AED Listing
                </Link>
                <Link to="/account/support-listing" className="nav-link">
                  Support Listing
                </Link>
                <Link to="/Admin/Note-listing" className="nav-link">
                  Notes Listing
                </Link> */}
              </Nav>
              <button to="" className="nav-link">
                <PermissionManagerMenu
                  setOpenDropdown={setOpenDropdown}
                  show={show}
                  setShow={setShow}
                />
              </button>
            </div>

            <div
              className="text-white p-1"
              style={{
                fontSize: "0.875rem",
                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                boxShadow: "inset 0 1px 0 rgba(255, 255, 255, 0.1)",
                cursor: "default",
                hover: "none",
              }}
            >
              version 1.0.0
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
