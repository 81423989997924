/*import React, { useState, useEffect, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import axios from "axios";
import debounce from 'lodash/debounce';
import { BASE_API } from "../../services";
// on checkbox
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from "@mui/material";
// import CheckBoxIcon from '@mui/icons-material/CheckBox';


const dropdownCache = new Map();

export default function PaginateAutoComplete({
    dropDownName = "parent_account_id",
    apiEndpoint = "/account/parents-account-dropdowns",
    idKey = "account_id",
    valueKey = "account_main_contact_firstname",
    parentKey = "parentAccount",
    tokenKey = 'ross_token',
    useApiSearch = true,
    isCache = false,
    onSelect = () => {},
    shouldFetchOnOpen = true,
    className = 'styles.ddLabel',
    selectDisabled = false,
    defaultValue = null,
    multiple = false,
    placeholder = "-- Select One --",
    excludeRecords = [],
    customRenderOption = null,
    customGetOptionLabel = null,
    showCheckBox = false
}) {
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedValue, setSelectedValue] = useState(multiple ? [] : null);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (defaultValue) {
            const processedDefaultValue = multiple
                ? (Array.isArray(defaultValue) ? defaultValue : [defaultValue])
                : defaultValue;
            setSelectedValue(processedDefaultValue);
            // Add default value(s) to the data array if not already present
            setData(prevData => {
                const newData = [...prevData];
                const valuesToAdd = Array.isArray(processedDefaultValue)
                    ? processedDefaultValue
                    : [processedDefaultValue];
                valuesToAdd.forEach(value => {
                    if (!newData.some(item => item[idKey] === value[idKey])) {
                        newData.push(value);
                    }
                });
                return newData;
            });
        } else {
            setSelectedValue(multiple ? [] : null);
        }
    }, [ multiple, idKey, defaultValue]);

    const fetchData = useCallback(async (searchText = "", pageNum = 1) => {
        const cacheKey = `${apiEndpoint}-${parentKey}-${pageNum}-${searchText}`;
        
        if (isCache && dropdownCache.has(cacheKey)) {
            const cachedData = dropdownCache.get(cacheKey);
            setData(prevData => [...prevData, ...cachedData.data]);
            setTotalCount(cachedData.totalCount);
            return;
        }

        setLoading(true);
        const token = localStorage.getItem(tokenKey);
        try {
            const response = await axios.get(`${BASE_API}${apiEndpoint}`, {
                params: { search: searchText, page: pageNum, pageSize: 20 },
                headers: { 'Authorization': `Bearer ${token}` },
            });

            const resultData = response?.data?.data?.[parentKey]?.data || [];
            const totalCountRes = response?.data?.data?.[parentKey]?.totalCount || 0;

            if (resultData.length > 0) {
                const cachingData = {
                    data: resultData,
                    totalCount: totalCountRes
                };
                dropdownCache.set(cacheKey, cachingData);
                setData(prevData => [...prevData, ...resultData]);
                setTotalCount(totalCountRes);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint, parentKey, isCache, tokenKey]);

    useEffect(() => {
        if (shouldFetchOnOpen) {
            fetchData('', 1);
        }
    }, [fetchData, shouldFetchOnOpen]);

    const handleChange = (event, value) => {
        setSelectedValue(value);
        onSelect({
            target: {
                name: dropDownName,
                value: multiple ? value.map(v => v[idKey]) : value?.[idKey] || "",
                formatted: multiple ? value.map(v => ({label: v[valueKey],  value:v[idKey]})) : value?.[idKey]
            }
        }, value);
    };

    const debouncedSearch = useCallback(
        debounce((term) => {
            if (term && useApiSearch) {
                setSearch(term);
                setPage(1);
                setData([]);
                fetchData(term, 1);
            }
        }, 300),
        [fetchData, useApiSearch]
    );

    const handleScroll = (event) => {
        const listbox = event.currentTarget;
        if (listbox.scrollHeight - listbox.scrollTop <= listbox.clientHeight + 1) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchData(search, nextPage);
        }
    };
// && t.value === item.value
    const removeDuplicates = (arr,idKey) => {
        const uniqueObjects = arr.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t?.[idKey] === item?.[idKey] 
          ))
        );
        
        return uniqueObjects;
      };
    const filteredOptions = useMemo(() => {
        const arr = data.filter(item => !excludeRecords.includes(item[idKey]));
        return removeDuplicates(arr,idKey);
    }, [data, excludeRecords, idKey]);

    const defaultRenderOption = (props, option,{selected}) => (
        <Box
            component="li"
            key={option[idKey]}
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            className={`text-black py-1 ${showCheckBox?'px-1':'px-3'}  cursor-pointer render-option`}
        >   
    
            {showCheckBox ? 
            <Checkbox
            //   icon={icon}
            //   checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            :""}
            {option[valueKey]}
        </Box>
    );

    const defaultGetOptionLabel = (option) => option[valueKey] || "";

    return (
        <Autocomplete
            multiple={multiple}
            className={`autocomplete-select-101 ${className}`}
            loading={loading}
            id={`Demo-${dropDownName}`}
            name={dropDownName}
            autoHighlight
            disableListWrap
            value={selectedValue}
            options={filteredOptions}
            getOptionLabel={customGetOptionLabel || defaultGetOptionLabel}
            isOptionEqualToValue={(option, value) => option[idKey] === value[idKey]}
            onChange={handleChange}
            onInputChange={(_, value, reason) => {
                if (reason === 'input') {
                    debouncedSearch(value);
                }
            }}
            renderOption={customRenderOption || defaultRenderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={multiple ? (selectedValue.length ? '' : placeholder) : placeholder}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                    }}
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            width:'100%',
                            height:  multiple ? "auto" : '37.3px', 
                            padding: '0px',
                        }
                    }}
                    />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={customGetOptionLabel ? customGetOptionLabel(option) : option[valueKey]}
                        {...getTagProps({ index })}
                        key={option[idKey]}
                    />
                ))
            }
            ListboxProps={{
                onScroll: handleScroll
            }}
            disabled={selectDisabled}
        />
    );
}*/










// -------------------------- Clear Bug in above code but working fine before ------------------------------

// --------------------------- New Code added here ----------------------------------------------------------


import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import axios from "axios";
import debounce from 'lodash/debounce';
import { BASE_API } from "../../services";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from "@mui/material";
import { dropdownCache } from "./dropdowns/cache";
import { printConsole } from "../../helper/Common";

// const dropdownCache = new Map();

export default function PaginateAutoComplete({
    dropDownName = "parent_account_id",
    apiEndpoint = "/account/parents-account-dropdowns",
    idKey = "account_id",
    valueKey = "account_main_contact_firstname",
    parentKey = "parentAccount",
    tokenKey = 'ross_token',
    useApiSearch = true,
    isCache = false,
    onSelect = () => {},
    shouldFetchOnOpen = true,
    className = 'styles.ddLabel',
    selectDisabled = false,
    defaultValue = null,
    multiple = false,
    placeholder = "-- Select One --",
    excludeRecords = [],
    customRenderOption = null,
    customGetOptionLabel = null,
    showCheckBox = false,
    showCountsOnly= 0,
    getResults = null,
    defaultOptions = null,
    pageCount = 20,
    isUpdated= false,
}) {
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedValue, setSelectedValue] = useState(multiple ? [] : null);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const currentEndpointRef = useRef(apiEndpoint);


       // Clear data when endpoint changes
       useEffect(() => {
        if (currentEndpointRef.current !== apiEndpoint) {
            setData([]);
            setTotalCount(0);
            setPage(1);
            setSearch('');
            if (isCache) {
                dropdownCache?.clearForEndpoint(currentEndpointRef.current);
            }
            currentEndpointRef.current = apiEndpoint;
        }
    }, [apiEndpoint, isCache]);

    useEffect(() => {
        if (defaultValue) {
            const processedDefaultValue = multiple
                ? (Array.isArray(defaultValue) ? defaultValue : [defaultValue])
                : defaultValue;
            setSelectedValue(processedDefaultValue);
            setData(prevData => {
                const newData = [...prevData];
                const valuesToAdd = Array.isArray(processedDefaultValue)
                    ? processedDefaultValue
                    : [processedDefaultValue];
                valuesToAdd.forEach(value => {
                    if (!newData.some(item => item[idKey] === value[idKey])) {
                        newData.push(value);
                    }
                });
                if(defaultOptions){
                    return [...defaultOptions, ...newData];
                }
                return newData;
            });
        } else {
            setSelectedValue(multiple ? [] : null);
        }
    }, [multiple, idKey, defaultValue]);

    const fetchData = useCallback(async (searchText = "", pageNum = 1) => {
        const cacheKey = `${apiEndpoint}-${parentKey}-${pageNum}-${searchText}`;
        
        if (isCache && dropdownCache.has(cacheKey)) {
            const cachedData = dropdownCache.get(cacheKey);
            printConsole({cachedData}) //print console.log
            if(cachedData?.data?.data){
                setData(prevData => [...prevData, ...cachedData?.data?.data]);
                setTotalCount(cachedData?.data?.totalCount);
                return;
            }
        }

        setLoading(true);
        const token = localStorage.getItem(tokenKey);
        try {
            const response = await axios.get(`${BASE_API}${apiEndpoint}`, {
                params: { search: searchText, page: pageNum, pageSize: pageCount },
                headers: { 'Authorization': `Bearer ${token}` },
            });

            
            let resultData = response?.data?.data?.[parentKey]?.data || [];
            let totalCountRes = response?.data?.data?.[parentKey]?.totalCount || 0;
            if(getResults){
                const [dataVar,totalCountsVar]  = getResults(response);
                resultData  = dataVar;
                totalCountRes  = totalCountsVar;
            }
            if (resultData.length > 0) {
                const cachingData = {
                    data: resultData,
                    totalCount: totalCountRes
                };
                dropdownCache.set(cacheKey, cachingData);
                setData(prevData => [...prevData, ...resultData]);
                setTotalCount(totalCountRes);
            } else {
                // If no results, clear the data
                setData([]);
                setTotalCount(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // In case of error, clear the data
            setData([]);
            setTotalCount(0);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint, parentKey, isCache, tokenKey]);

    useEffect(() => {
        if (shouldFetchOnOpen) {
            fetchData('', 1);
        }
    }, [fetchData, shouldFetchOnOpen]);
    
    useEffect(()=>{
        if(isUpdated){
            dropdownCache?.clearForEndpoint(currentEndpointRef.current);
            setData([]);
            setPage(1);
            fetchData('', 1);
        }
    },[isUpdated, fetchData])

    const handleChange = (event, value) => {
        setSelectedValue(value);
        onSelect({
            target: {
                name: dropDownName,
                value: multiple ? value.map(v => v[idKey]) : value?.[idKey] || "",
                formatted: multiple ? value.map(v => ({label: v[valueKey],  value:v[idKey]})) : value?.[idKey]
            }
        }, value);
    };

    const debouncedSearch = useCallback(
        debounce((term) => {
            if (useApiSearch) {
                setSearch(term);
                setPage(1);
                setData([]);
                fetchData(term, 1);
            }
        }, 300),
        [fetchData, useApiSearch]
    );

    const handleScroll = (event) => {
        //  total pages is not available that's why didn't stop to load the values 
        //  we can fix this bug after using total result counts in the each and every api inside the dropdown's
        const arr = data.filter(item => !excludeRecords.includes(item[idKey]));
        const FinalData =  removeDuplicates(arr);
        if(totalCount > FinalData.length){
            const listbox = event.currentTarget;
            if (listbox.scrollHeight - listbox.scrollTop <= listbox.clientHeight + 1) {
                const nextPage = page + 1;
                setPage(nextPage);
                fetchData(search, nextPage);
            }
        }
    };

    const removeDuplicates = (arr) => {
        const uniqueObjects = arr.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t?.[idKey] === item?.[idKey] 
          ))
        );
        
        return uniqueObjects;
    };

    const filteredOptions = useMemo(() => {
        const arr = data.filter(item => !excludeRecords.includes(item[idKey]));
        return removeDuplicates(arr);
    }, [data, excludeRecords, idKey]);

    const defaultRenderOption = (props, option, { selected }) => (
        <Box
            component="li"
            key={option[idKey]}
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            className={`text-black py-1 ${showCheckBox ? 'px-1' : 'px-3'}  cursor-pointer render-option`}
        >   
            {showCheckBox && 
                <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
            }
            {option[valueKey]}
        </Box>
    );

    const defaultGetOptionLabel = (option) => option[valueKey] || "";

    const handleInputChange = (event, value, reason) => {
        if (reason === 'input') {
            debouncedSearch(value);
        } else if (reason === 'clear') {
            setSearch("");
            setData([]);
            setPage(1);
            if (shouldFetchOnOpen) {
                fetchData('', 1);
            }
        }
    };

    return (
        <>
        <Autocomplete
            multiple={multiple}
            className={`autocomplete-select-101 ${className}`}
            loading={loading}
            id={`Demo-${dropDownName}`}
            name={dropDownName}
            autoHighlight
            disableListWrap
            disableCloseOnSelect={multiple? true :false}
            value={selectedValue}
            options={filteredOptions}
            getOptionLabel={customGetOptionLabel || defaultGetOptionLabel}
            isOptionEqualToValue={(option, value) => option[idKey] === value[idKey]}
            onChange={handleChange}
            onInputChange={handleInputChange}
            renderOption={customRenderOption || defaultRenderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={multiple ? (selectedValue.length ? '' : placeholder) : placeholder}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                    }}
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            width:'100%',
                            height: multiple ? "auto" : '37.3px', 
                            padding: '0px',
                        }
                    }}
                />
            )}
            // renderTags={(tagValue, getTagProps) =>
            //     tagValue.map((option, index) => (
            //         <Chip
            //             label={customGetOptionLabel ? customGetOptionLabel(option) : option[valueKey]}
            //             {...getTagProps({ index })}
            //             key={option[idKey]}
            //         />
            //     ))
            // }

            renderTags={(selected, getTagProps) => {
                if (showCountsOnly) {
                  return `${selected.length} selected`;
                }
                return selected.map((option, index) => (
                  <Chip key={index} label={option} {...getTagProps({ index })} />
                ));
              }}
            ListboxProps={{
                onScroll: handleScroll
            }}
            disabled={selectDisabled}
            onOpen={() => {
                // setOpen(true)
                fetchData('', 1);
            }}
            // onClose={() => setOpen(false)}
        />
        </>
    );
}